import PropTypes from 'prop-types';
import React from 'react';

const Reorder = ({ reverse, children, ...props }) => {
    return reverse && children ? React.Children.toArray(children).reverse() : children;
};

Reorder.propTypes = {
    reverse: PropTypes.bool,
    className: PropTypes.string,
};

export default Reorder;
