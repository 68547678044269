import { MediaText } from '@/design-system/molecules/MediaText';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const IntegrationMediaText = (props) => {
    const modifiers = generateStyleModifiers(props.appliedCssClassNames);
    const theme = modifiers.theme;
    const placement = modifiers.orientation;

    return <MediaText {...props} theme={theme} placement={placement} />;
};

export default IntegrationMediaText;
