import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonGroup } from '@/design-system/atoms/ButtonGroup';
import { CardVideo } from '@/design-system/atoms/Cards/CardVideo';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import { Reorder } from '@/design-system/helpers/Reorder';

import styles from './MediaText.module.scss';
/**
 * The Media & Text component is comprised of 2 columns content. This is a versatile component that allows for a combination of text with an image or a video. The columns are interchangeable, allowing authors to display certain content on either the left or right of the component.
 * The Component allows for Three themes, by default the theme is Medium which has a light grey background. The other theme options are Default and Article which have white backgrounds. The article theme has a fall colored eyebrow.
 *
 */

const MediaText = ({
    theme,
    placement = 'right',
    eyebrow,
    title,
    body,
    ctas,
    media,
    image,
    video,
    ...props
}) => {
    var hasBgColor = true;
    if (theme === 'article' || theme === Theme.NAME.DEFAULT) {
        hasBgColor = false;
    }
    return (
        <ContentContainer
            className={classnames(
                styles[`media-text`],
                hasBgColor && styles[`media-text--not-default`],
            )}
        >
            <Theme
                name={theme === 'article' ? Theme.NAME.DEFAULT : theme}
                className={classnames(
                    styles[`media-text__spacing-wrapper`],
                    hasBgColor && styles[`media-text__spacing-wrapper--not-default`],
                )}
                {...props}
            >
                <Grid verticalAlignment="center">
                    <Reorder reverse={placement === 'left'}>
                        <GridColumn
                            colSpan={{ sm: 4, md: 3, lg: 6 }}
                            className={classnames(styles[`media-text__content`], {
                                [styles[`media-text__content--left`]]: placement === 'left',
                            })}
                        >
                            <div className={styles[`media-text__content-inner`]}>
                                {eyebrow && (
                                    <Text
                                        tag="p"
                                        variant={Text.VARIANT.E1}
                                        content={eyebrow}
                                        className={
                                            theme === 'article' &&
                                            styles[`media-text__article-eyebrow`]
                                        }
                                    />
                                )}
                                {title && (
                                    <Title
                                        tag={Title.TAG.H2}
                                        variant={Title.VARIANT.H3}
                                        content={title}
                                        lineClamp={{ sm: 3, lg: 2 }}
                                    />
                                )}
                                {body && (
                                    <Text
                                        tag="p"
                                        variant={Text.VARIANT.T1}
                                        content={body}
                                        lineClamp={{ sm: 12, lg: 8 }}
                                    />
                                )}
                            </div>

                            {Array.isArray(ctas) && ctas.length !== 0 && (
                                <ButtonGroup
                                    buttons={ctas}
                                    className={styles[`media-text__buttons`]}
                                    fullWidth={{ md: true }}
                                    analytics={{
                                        componentName: 'Media and Text',
                                        componentTitle: title,
                                    }}
                                />
                            )}
                        </GridColumn>
                        <GridColumn
                            colSpan={{ sm: 4, md: 3, lg: 6 }}
                            className={styles[`media-text__media`]}
                        >
                            {media === 'image' && image && (
                                <Image
                                    {...image}
                                    roundedCorners
                                    defaultAspectRatio="4/3"
                                    mobileAspectRatio="4/3"
                                />
                            )}
                            {media === 'video' && video && (
                                <CardVideo
                                    className={styles[`media-text__video`]}
                                    {...video}
                                    analytics={{}}
                                />
                            )}
                        </GridColumn>
                    </Reorder>
                </Grid>
            </Theme>
        </ContentContainer>
    );
};

MediaText.PLACEMENT = {
    LEFT: 'left',
    RIGHT: 'right',
};

MediaText.defaultProps = {
    theme: Theme.NAME.MEDIUM,
};

MediaText.propTypes = {
    theme: PropTypes.oneOf([Object.values(Theme.NAME), 'article']),
    placement: PropTypes.oneOf(Object.values(MediaText.PLACEMENT)),
    eyebrow: PropTypes.string,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    ctas: ButtonGroup.propTypes.buttons,
    media: PropTypes.oneOf(['image', 'video']),
    image: PropTypes.shape(Image.propTypes),
    video: PropTypes.shape(CardVideo.propTypes),
};

export default MediaText;
